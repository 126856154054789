<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>{{ role_name }} email template </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ {{ role_name }} email templates
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">

                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="2">
                  <v-text-field  v-on:keyup.enter="searchEmailTemplate"
                                 @input="search.info = $event !== null ? $event : ''"
                                 label="Lookup by template title"
                                 v-model="search.info" outlined dense clearable>

                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select v-model="search.score_id" :items="scores"
                            label="SCORE"
                            item-text="name"  v-on:keyup.enter="searchEmailTemplate"
                            item-value="id"
                            clearable
                            outlined
                            :disabled="currentUser.access_type == 'score'"
                            dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field v-model="search.subject"
                                label="Search by subject"
                                v-on:keyup.enter="searchEmailTemplate"
                                outlined
                                dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-select v-model="search.roles" :items="roles"
                            label="Roles"
                            item-text="text"  v-on:keyup.enter="searchEmailTemplate"
                            item-value="value"
                            outlined
                            dense
                  ></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6" md="2">
                  <v-select
                      :items="status"
                      v-model="search.is_active"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      v-on:keyup.enter="searchEmailTemplate"
                      dense clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-btn :loading="isLoading"
                      @click="searchEmailTemplate"
                      class="btn w-35 btn-primary float-right"
                      style="color: #fff;"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                  <th class="px-3" style="max-width: 200px !important; white-space: pre-wrap;">Default reference</th>
                  <th class="px-3" style="max-width: 250px !important; white-space: pre-wrap;">Template title</th>
                  <th class="px-3" style="max-width: 170px !important; white-space: pre-wrap;">Subject</th>
                  <th class="px-3" style="max-width: 170px !important; white-space: pre-wrap;" v-if="currentUser.access_type == 'federal'">SCORE</th>
                  <th class="px-3" style="max-width: 170px !important; white-space: pre-wrap;">Status</th>
                  <th class="px-3 text-center">Options</th>
                </thead>
                <tbody v-if="email_templates.length>0">
                <tr v-for="(email_template, index) of email_templates" :key="index">
                  <td class="px-2" style="max-width: 200px !important; white-space: pre-wrap;">
                      <a href="javascript:;" @click="viewInfo(email_template)" v-if="email_template.about_template" class="p-1">
                        <i class="fa fa-info"></i>
                      </a>
                      {{ email_template.identifier }}
                  </td>
                  <td  class="px-2" style="max-width: 250px !important; white-space: pre-wrap;">
                    <a  class="text-dark-75 text-hover-primary "  @click="__edit(email_template.id)">{{ email_template.title }}
                    </a>
                    <div class="mt-1">
                      <span class="p-1" v-bind:class="{ 'badge-warning': email_template.score_id, 'badge-primary': !email_template.score_id }"> {{ email_template.score_id ? 'Custom template for '+email_template.score_prefix : 'Default Template' }}</span>
                    </div>
                  </td>

                  <td class="px-2" style="max-width: 170px !important; white-space: pre-wrap;">

                      {{ email_template.subject ? email_template.subject : 'NA' }}

                  </td>
                  <td class="px-2" style="max-width: 170px !important; white-space: pre-wrap;" v-if="currentUser.access_type == 'federal'">

                    {{ email_template.score_prefix ? email_template.score_prefix : 'NA' }}

                  </td>
                  <td class="px-2" style="max-width: 170px !important; white-space: pre-wrap;">
                     <span class="badge" v-bind:class="{ 'badge-success': email_template.is_active, 'badge-danger': !email_template.is_active }">
                       {{ email_template.status_text }}
                     </span>
                  </td>
                  <td class="pr-0 text-center">
                    <template v-if="currentUser.access_type =='score'">
                      <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                          v-if="currentUser.score_id == email_template.score_id || !email_template.disable_customize"
                      >
                        <template v-slot:button-content>
                          <i class="ki ki-bold-more-hor"></i>
                        </template>
                        <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                          <b-dropdown-text tag="div" class="navi-item" v-if="currentUser.score_id == email_template.score_id">
                            <a @click="__edit(email_template.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-edit"></i>
                                </span>
                              <span class="navi-text">Edit</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="!email_template.disable_customize">
                            <a @click="openCloneForm(email_template)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-clone"></i>
                                </span>
                              <span class="navi-text">Create a local template</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                      </b-dropdown>
                      <span v-else class="text-body">N/A</span>
                    </template>
                    <template v-if="currentUser.access_type !='score'">
                      <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                      >
                        <template v-slot:button-content>
                          <i class="ki ki-bold-more-hor"></i>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover min-w-md-250px">
                          <b-dropdown-text tag="div" class="navi-item">
                            <a @click="__edit(email_template.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-edit"></i>
                                </span>
                              <span class="navi-text">Edit</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item">
                            <a @click="openCloneForm(email_template)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-clone"></i>
                                </span>
                              <span class="navi-text">Create a local template</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                      </b-dropdown>
                    </template>
                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="50" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </table>
<!--              <b-pagination-->
<!--                  v-if="email_templates.length > 0"-->
<!--                  class="pull-right mt-7"  :disabled="isLoading"-->
<!--                  @input="getTemplates"-->
<!--                  v-model="page"-->
<!--                  :total-rows="total"-->
<!--                  :per-page="perPage"-->
<!--                  first-number-->
<!--                  last-number-->
<!--              ></b-pagination>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="cloneDialog" max-width="500">
      <v-card>
        <v-card-title>
          Clone email template for
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="12" >
                <v-autocomplete
                    label="SCORE*"
                    outlined
                    dense item-text="name"
                    item-value="id"
                    :items="scores" :error="$v.email_template.score_id.$error"
                    v-model="email_template.score_id">
                  <template v-slot:label>
                    SCORE <span class="text-danger">*</span>
                  </template>
                </v-autocomplete>
                <span class="text-danger" v-if="$v.email_template.score_id.$error">Select SCORE</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                x-large
                text
                @click="closeCloneForm">
              Cancel
            </v-btn>
            <v-btn
               x-large
                dark :loading="cloneLoading"
                 @click="clone">
               Clone template
            </v-btn>


          </v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmCloneDialog" max-width="800">
      <v-card>
        <v-card-title>
          You already have a local version for this email template. Do you want to create another?
        </v-card-title>
        <v-card-text v-if="showExistingEmail">
            <v-container>
              <v-row>
                  <v-col cols="12">
                    <h6>{{ duplicateEmailTemplate.title }}</h6>
                  </v-col>

                  <v-col cols="12">
                    <p><b>Identifier: </b> {{duplicateEmailTemplate.identifier}}</p>
                    <p>  <b>Title: </b> {{duplicateEmailTemplate.title}}</p>
                    <p><b>Email subject: </b> {{duplicateEmailTemplate.subject}}</p>
                    <p><b>Content: </b> <span v-html="duplicateEmailTemplate.description"></span></p>
                  </v-col>
              </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                x-large
                v-if="!viewExistingEmailButton"
                text
                @click="viewExistingEmail">
              View existing email
            </v-btn>
            <v-btn
                x-large
                v-if="viewExistingEmailButton"
                text
                @click="cancelAction">
              Cancel
            </v-btn>
            <v-btn
               x-large
                dark :loading="cloneLoading"
                 @click="cloneExistingTemplate">
              Continue
            </v-btn>
          </v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewTrigger" max-width="500">
      <v-card>
        <v-card-text>
          <v-container>
            <div v-html="aboutTemplate" style="font-size:16px;" class="mt-4"></div>
          </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                x-large
                text
                @click="viewTrigger = false;">
              Close
            </v-btn>
          </v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>
<script>
import EmailTemplateService from "@/services/email-template/EmailTemplateService";
import ScoreService from "@/services/score/score/ScoreService";
import {minLength, required, sameAs} from "vuelidate/lib/validators";
const score = new ScoreService();
const emailTemplate = new EmailTemplateService();

export default {
  name: "email-templates",
  components: {},
  data() {
    return {
      email_templates: [],
      page: null,
      total: null,
      perPage: null,
      search: {
        info:'',
        score_id: "",
        subject: "",
        is_active: "",
        roles: '',
      },
      isLoading:false,
      roles: [
        {'text': 'Enroller', 'value': 'account_holder'},
        {'text': 'General', 'value': 'general'},
        {'text': 'Candidate', 'value': 'candidate'},
        {'text': 'Examiner', 'value': 'examiner'},
        {'text': 'Supervisor', 'value': 'supervisor'},
        {'text': 'Online marker', 'value': 'online_marker'},
        {'text': 'School Account', 'value': 'school_account'},
        {'text': 'Venue', 'value': 'venue'},
      ],
      cloneDialog:false,
      confirmCloneDialog:false,
      scores:[],
      email_template:{
        id:'',
        score_id:'',
        title:'',
        identifier:'',
        role:'',
        type:'',
        subject:'',
        description:'',
        accepted_inputs:'',
        is_active:true,
      },
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      cloneLoading:false,
      currentUser:{},
      existing_email_template:null,
      selectedTemplate: {},
      showExistingEmail: false,
      duplicateEmailTemplate: {},
      viewExistingEmailButton: false,
      viewTrigger: false,
      aboutTemplate: ''
    };
  },
  computed: {
    rules() {
      return {
        email_template: {
          score_id: { required },
        }
      }
    },
    role() {
      return this.$route.params.role
    },
    role_name() {
      let role = this.$route.params.role
      role = role.charAt(0).toUpperCase() + role.slice(1);
      role = role.replace("_", " ");
      if(role=='Account holder'){
        return 'Enroller';
      }
      else
        return role;
    }
  },
  mounted() {
    this.search.roles = this.role;
    this.getCurrentUser();
    this.getTemplates();
    this.getAllScore();
  },
  validations(){
      return this.rules;
  },
  methods: {
    viewExistingEmail(){
      // this.$router.push({
      //   name: "email-template-update",
      //   params: {id: this.existing_id}
      // });
      this.viewExistingEmailButton = true;
      this.getExistingEmail();
    },
    cancelAction(){
      this.confirmCloneDialog = false;
    },
    getExistingEmail(){
      let data={
        'score_id':this.currentUser.score_id,
        'role':this.selectedTemplate.role,
        'type':this.selectedTemplate. type,
      }
      emailTemplate
      .getExistingEmail(data)
      .then((res) => {
        this.showExistingEmail = false;
        if(res.data.status == 'OK'){
          this.duplicateEmailTemplate = res.data.emailTemplate;
          this.showExistingEmail = true;
          return true;
        }
      })
      .catch((err) => {

      })
    },
    checkDuplicateLocalScore(item){
      let data={
        'score_id':this.currentUser.score_id,
        'role':item.role,
        'type':item.type,
      }
      this.selectedTemplate = item;

      emailTemplate
          .checkDuplicateLocalTemplate(item.id,data)
          .then(response => {
            if(response.data.status=='ERROR'){
              if(response.data.emailTemplate){
                this.existing_email_template=response.data.emailTemplate
                this.confirmCloneDialog=true;
              }
            }
            if(response.data.status=='SUCCESS'){
              this.clone()
            }
          })
          .catch((err) => {

          })
          .finally(() => {

          });
    },
    cloneExistingTemplate(){

      let data={
        'is_active':0,
      }
      emailTemplate
          .changeStatus(this.existing_email_template.id, data)
          .then(response => {
            this.clone();
            this.existing_email_template=null;
            this.confirmCloneDialog=false;
          })
          .catch((err) => {
          })
          .finally(()=>{

          });
    },
    openCloneForm(item){
      this.email_template=item;
      if(this.currentUser.access_type!='score'){
        this.cloneDialog=true;
      }else {
        this.email_template.score_id=this.currentUser.score_id;
        this.checkDuplicateLocalScore(item);
       /* this.clone();*/
      }

    },
    closeCloneForm(){
      this.cloneDialog=false;
      this.email_template={
            id:'',
            score_id:'',
            title:'',
            identifier:'',
            role:'',
            type:'',
            subject:'',
            description:'',
            accepted_inputs:'',
            is_active:true,
      };
    },
    getTemplates() {
      this.isLoading = true;
        emailTemplate
          .paginate(this.search, this.page)
          .then(response => {
              this.email_templates = response.data.data;
              this.page = response.data.meta.current_page;
              this.total = response.data.meta.total;
              this.perPage = response.data.meta.per_page;
              this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
          });
    },
    __edit(id) {
      this.$router.push({
        name: "email-template-update",
        params: {id: id}
      });
    },
    searchEmailTemplate() {
      this.getTemplates();
    },
    clone(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.cloneLoading = true;
        this.email_template.role = this.role;
        emailTemplate
            .clone(this.email_template)
            .then(response => {
              this.$snotify.success("Email template customised");
              this.cloneLoading = false;
              this.getTemplates();
              this.closeCloneForm();

            })
      }
    },
    getAllScore(){
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
      // if(this.currentUser.access_type == 'federal'){
      //   this.search.score_id = this.currentUser.score_id;
      // }else{
      //   this.search.score_id = null;
      // }
    },
    viewInfo(email_template){
      this.viewTrigger = true;
      this.aboutTemplate = email_template.about_template;
    }
  },

};
</script>
